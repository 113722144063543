import { resolveComponent as _resolveComponent, createVNode as _createVNode, withKeys as _withKeys, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_alert = _resolveComponent("el-alert")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_card = _resolveComponent("el-card")!
  const _component_el_container = _resolveComponent("el-container")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, {
          xs: 1,
          sm: 2,
          md: 4,
          lg: 6,
          xl: 8
        }),
        _createVNode(_component_el_col, {
          xs: 22,
          sm: 20,
          md: 18,
          lg: 12,
          xl: 8
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_container, { id: "userContainer" }, {
              default: _withCtx(() => [
                (_ctx.user)
                  ? (_openBlock(), _createBlock(_component_el_card, {
                      key: 0,
                      id: "user-card"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_form, {
                          model: _ctx.form,
                          "label-width": "auto",
                          "label-position": "left",
                          rules: _ctx.rules
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_form_item, {
                              label: "Oud wachtwoord",
                              prop: "oldPassword"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_input, {
                                  type: "password",
                                  id: "formPassword",
                                  modelValue: _ctx.form.oldPassword,
                                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.oldPassword) = $event)),
                                  onKeyup: _withKeys(_ctx.onChangePassword, ["enter"])
                                }, null, 8 /* PROPS */, ["modelValue", "onKeyup"])
                              ]),
                              _: 1 /* STABLE */
                            }),
                            _createVNode(_component_el_form_item, {
                              label: "Nieuw wachtwoord",
                              prop: "newPassword"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_input, {
                                  type: "password",
                                  id: "formNewPasswordOne",
                                  modelValue: _ctx.form.newPassword,
                                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.newPassword) = $event)),
                                  onKeyup: _withKeys(_ctx.onChangePassword, ["enter"])
                                }, null, 8 /* PROPS */, ["modelValue", "onKeyup"])
                              ]),
                              _: 1 /* STABLE */
                            }),
                            _createCommentVNode(" Submit button "),
                            _createVNode(_component_el_form_item, { justify: "space-evenly" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_col, {
                                  span: _ctx.$isMobile() ? 24 : 4
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_button, {
                                      type: "primary",
                                      block: "",
                                      onClick: _withModifiers(_ctx.onChangePassword, ["prevent"])
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode("Inloggen")
                                      ]),
                                      _: 1 /* STABLE */
                                    }, 8 /* PROPS */, ["onClick"])
                                  ]),
                                  _: 1 /* STABLE */
                                }, 8 /* PROPS */, ["span"])
                              ]),
                              _: 1 /* STABLE */
                            }),
                            (_ctx.errorMessage)
                              ? (_openBlock(), _createBlock(_component_el_alert, {
                                  key: 0,
                                  type: "error"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.errorMessage), 1 /* TEXT */)
                                  ]),
                                  _: 1 /* STABLE */
                                }))
                              : _createCommentVNode("v-if", true)
                          ]),
                          _: 1 /* STABLE */
                        }, 8 /* PROPS */, ["model", "rules"])
                      ]),
                      _: 1 /* STABLE */
                    }))
                  : _createCommentVNode("v-if", true)
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_el_col, {
          xs: 1,
          sm: 2,
          md: 4,
          lg: 6,
          xl: 8
        })
      ]),
      _: 1 /* STABLE */
    })
  ]))
}