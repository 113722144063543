import { SCHRANZ_API_URL } from '../config';
import { request, RequestData } from './request';

export type UpdatePasswordData = {
  oldPassword: string;
  newPassword: string;
};

export async function updatePassword({
  oldPassword,
  newPassword,
}: UpdatePasswordData): Promise<{ status: number | null; data: any }> {
  const payload: RequestData = {
    method: 'patch',
    url: `${SCHRANZ_API_URL}/auth/password`,
    data: {
      oldPassword,
      newPassword,
    },
  };

  const { status, data } = await request(payload);

  return { status, data };
}
