
import { Component } from '@vue/runtime-core';
import { mapState } from 'vuex';
import { updatePassword } from '../composables/updatePassword';
import { logout } from '../composables/logout';
import { reactive } from 'vue';

export default {
  setup() {
    const form = reactive({
      oldPassword: '',
      newPassword: '',
    });

    return {
      form,
    };
  },

  computed: {
    ...mapState('authorization', ['user', 'role']),
  },

  mounted() {},

  methods: {
    async onChangePassword() {
      this.errorMessage = '';

      if (this.form.oldPassword === this.form.newPassword) {
        this.errorMessage = 'Nieuw wachtwoord kan niet hetzelfde zijn!';
        return;
      }

      try {
        const { status, data } = await updatePassword({
          oldPassword: this.form.oldPassword,
          newPassword: this.form.newPassword,
        });

        if (status !== 201) throw new Error(`${data.toString()}`);
        logout();
      } catch (e: any) {
        this.errorMessage = e.message;
      }
    },
  },

  data() {
    return {
      errorMessage: '',
    };
  },
} as Component;
